import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

export default function useRouteEventSubscriptions(...args) {
    const router = useRouter();
    const helpers = {
        args,
        router
    };
    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    useEffect(() => {
        const handleRouterEvents = method => {
            const { args, router } = helpersRef.current;

            args.forEach(({ eventName, eventHandler }) => {
                router.events[method](eventName, eventHandler);
            });
        };

        handleRouterEvents('on');
        return () => {
            handleRouterEvents('off');
        };
    }, []);
};
