import { createContext, memo } from "react";
import useMobileMenu from "./useMobileMenu";
import useRouteEventSubscriptions from "@/utils/customHooks/useRouteEventSubscriptions";

export const MobileMenuContext = createContext(null);

const MobileMenuProvider = ({ children }) => {
    const mobileMenu = useMobileMenu();

    useRouteEventSubscriptions({
        eventName: 'routeChangeStart',
        eventHandler: mobileMenu.close
    });

    return (
        <MobileMenuContext.Provider
            value={mobileMenu}
        >
            {children}
        </MobileMenuContext.Provider>
    );
};

export default memo(MobileMenuProvider);
